<template>
  <b-card>      
    <!-- BODY -->
    <validation-observer ref="inventoryValidation">
      <b-form
      class="p-2"
      @submit.prevent=""
      @reset.prevent
      > 
        <b-row>        
          <!-- Medical Rep -->
          <b-col md="6" xl="4" class="mb-1">
              <validation-provider
                  #default="validationContext"
                  name="medicalRep"
                  rules="required"

              >
                <b-form-group
                label="Select Medical Rep"
                label-for="medicalRep"
                >
                    <v-select
                        id="medicalRep"
                        v-model="medical_rep_id"
                        :state="getValidationState(validationContext)"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="medicalReps"
                        :reduce="types => types.id"
                        select
                        class="form-control-merge"
                        @input="getHospitalsByRegion"

                    />
                    <div v-if="validationContext.errors[0]" class="invalid-feedback">{{ validationContext.errors[0] }}</div>
                <b-form-invalid-feedback :force-show="!medical_rep_id">
                    {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <span class="text-danger text-sm" v-if="allerrors.medical_rep_id">{{ allerrors.medical_rep_id[0] }}</span>
                </b-form-group>
            </validation-provider>
          </b-col>     
          
           <!-- date -->
           <b-col md="6" xl="4" class="mb-1">
            <validation-provider
                #default="validationContext"
                name=" Date"
                rules="required"
            >
                <b-form-group
                label="Date"
                label-for="date"
                >
                <flat-pickr
                    id="date"
                    v-model="date"
                    class="form-control"
                    :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback :force-show="!date">
                    {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <span class="text-danger text-sm" v-if="allerrors.date">{{ allerrors.date[0] }}</span>
                </b-form-group>
            </validation-provider>

        </b-col>

        </b-row>


        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >

          <!-- Hospitals -->
          <b-col md="3">
          <validation-provider
              #default="validationContext"
              name="hospital"
              rules="required"
          >
              <b-form-group
              label-for="hospital"
              >
                <v-select
                    id="hospital"
                    v-model="item.hospital_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="hospitalsByRegion"
                    :reduce="type => type.id"
                    :state="getValidationState(validationContext)"
                    select
                    @input="getDoctors(item.hospital_id, index)"
                    placeholder="Hospitals"
                />
              <b-form-invalid-feedback :force-show="!item.hospital_id" >
                  {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.hospital_id">{{ allerrors.hospital_id[0] }}</span>
              </b-form-group>
          </validation-provider>
          </b-col>


          <!-- Doctors -->
          <b-col md="3">
              <validation-provider
                  #default="validationContext"
                  name="doctor_id"
                  rules="required"
              >
                  <b-form-group
                  label-for="doctor">
                      <v-select
                          id="doctor_id"
                          v-model="item.doctor_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="item.doctors"
                          :reduce="types => types.doctor_id"
                          :state="getValidationState(validationContext)"
                          select
                          placeholder="Doctors"
                      />
                  <b-form-invalid-feedback :force-show="!item.doctor_id" >
                      {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <span class="text-danger text-sm" v-if="allerrors.doctor_id">{{ allerrors.doctor_id[0] }}</span>
                  </b-form-group>
              </validation-provider>
          </b-col>


          <!-- visit type -->
          <b-col md="3">
              <validation-provider
                  #default="validationContext"
                  name="visit_type_id"
                  rules="required"
              >
                  <b-form-group
                  label-for="slug"
                  >
                      <v-select
                          id="visit_type_id"
                          v-model="item.visit_type_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="visitTypes"
                          :reduce="types => types.id"
                          :state="getValidationState(validationContext)"
                          select
                          placeholder="Visit Type"
                      />
                  <b-form-invalid-feedback :force-show="!item.visit_type_id">
                      {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <span class="text-danger text-sm" v-if="allerrors.visit_type_id">{{ allerrors.visit_type_id[0] }}</span>
                  </b-form-group>
              </validation-provider>
          </b-col>

          <!-- Remove Button -->
          <b-col
            lg="2"
            md="3"
            style="margin-top: 0rem !important; margin-bottom: 0rem; height: 45px"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
            </b-button>
          </b-col>
          
        </b-row>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Add New Product</span>
        </b-button>



          <br/>
          <b-row>
          <!-- Notes -->
          <b-col md="8" xl="8" class="mb-1">
              <b-form-group
              label="Note"
              label-for="decription-name"
              >
                <b-form-textarea
                    id="textarea-auto-height"
                    placeholder="Enter Notes"
                    rows="3"
                    max-rows="8"
                    v-model="notes"
                />
                <span class="text-danger text-sm" v-if="allerrors.notes">{{ allerrors.notes[0] }}</span>                           
              </b-form-group>

          </b-col>
        </b-row>
                <!-- Form Actions -->    
            </b-form>

        </validation-observer>


         <!-- Form Actions -->
         <div class="d-flex mt-2">
            <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="validationForm"
            v-if="!loading"
            >
            Add
            </b-button>
            <!-- spinner -->
            <b-button
            v-if="loading"
            variant="primary"
            disabled
            class="mr-1"
            >
            <b-spinner small />
            Loading...
            </b-button>
            <!-- end spinner -->
        </div>


</b-card>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BRow, BCol, BFormInvalidFeedback, BButton, BFormFile, BCard, BCardText, BMedia, BAvatar, BSpinner, BFormTextarea, BInputGroupAppend, BInputGroup,BFormTags, BBadge
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, alphaNum, email, integer } from '@validations'
import Ripple from 'vue-ripple-directive'
import DataServices from './data/services'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BCardText,
    BMedia,
    BAvatar,
    vSelect,
    BFormTextarea,
    BInputGroupAppend,
    BInputGroup,
    BSpinner,
    BRow,
    BCol,
    BCard,
    BForm,
    BFormTags,

    ValidationProvider,
    ValidationObserver,
    
    BBadge,
    flatPickr,
    ToastificationContent

  },
  directives: {
    Ripple,
  },
  data() {
    return {
        required,
        alphaNum,
        email,
        integer,
        medical_rep_id: '',
        date: '',
        notes: '',
        visitTypes: [],
        visit_type_id: 1,
        hospitals: [],
        hospitalsByRegion: [],
        headerType: 'Add',
        loading: false,
        medical_rep_id: '',
        allerrors: [],
        isAddNewCompanySidebarActive: false,
        isAddNewUnitSidebarActive: false,
        isAddNewMedicalRepsActive: false,
    }
  },
  methods: {
    validationForm() {
      this.$refs.inventoryValidation.validate().then(success => {
        if (success) {
          this.sendData()
        }
      })
      .catch(error => {
        console.log(error)
      })
    },  
    sendData(){
      this.loading = true;
        DataServices.create(this.prepareData())
        .then(response => {
          this.loading = false
          let responseCode = response.data.responseCode
          if(responseCode == 100){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.responseMessage,
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.responseDescription
              },
            })
            this.$router.push('/visits')  
          }
          else if(responseCode === 101){
            console.log('error');
            this.allerrors = response.data.errors
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
          else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }
          let message = Error
          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },

    addNewCompany() {
      this.toggleCompanySidebar(true)
    },
    toggleCompanySidebar(val=false) {
      this.isAddNewCompanySidebarActive = val
    },
    addNewMedicalReps(){
        this.toggleMedicalRepsSidebar(true)
    },
    toggleMedicalRepsSidebar(val=false) {
        this.isAddNewMedicalRepsActive = val
    },
    refreshMedicalRepData(newData) {
        this.medicalReps = newData
    },
    refreshCompanyData(newData){
      this.companies = newData
    },
    onImageChange(e) {
      const file = e.target.files[0];
      this.products_image = file
      this.imageUrl = URL.createObjectURL(file);
    },
    initValues(){
        this.medical_rep_id= null
        this.date= null
        this.notes = null

        this.repeateAgain()
    },

    prepareData() {
      return {
          notes: this.notes,
          date: this.date,
          medical_rep_id: this.medical_rep_id,
          visits: this.$store.state.admin.visits
      }
    },
    getProductAttribute(){
      // console.log(id);
      DataServices.getProductAttribute()
      .then(response => {
        this.productAttributes = response.data.data
      })
    },
    calcTotal(index){
      this.$store.commit('admin/UpdateVisit', {index: index});
    },
    repeateAgain() {
      this.$store.commit('admin/AddVisit', {hospitals: [],  hospital_id: '', doctors:[], doctor_id: '',visit_type_id: 1} )
    },
    removeItem(index) {
      this.$store.commit('admin/RemoveVisit', index)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    
    getHospitals(){
        this.isLoading = true;
        DataServices.getHospitals()
        .then(response => {
          this.isLoading = false
          this.hospitals = response.data.data
        })
    },
    getVisitType(){
        this.isLoading = true;
        DataServices.getvisitType()
        .then(response => {
          this.isLoading = false
          this.visitTypes = response.data.data
        })
    },

    getDoctors(val, index){
      this.items[index].doctors = []
      Object.entries(this.hospitals).forEach(([key, value]) => {
        if(val == value.id){
          this.items[index].doctors = value.doctor_with_specialty
        }
      });
    },

    getHospitalsByRegion(val){ 
      this.hospitalsByRegion = []
      Object.entries(this.medicalReps).forEach(([key, value]) => {
        if(val == value.id){
          this.prepareHospitalsByRegion(value.region_id)
        }
      });
    },
    prepareHospitalsByRegion(regionId){
      Object.entries(this.hospitals).forEach(([key, value]) => {
        if(regionId == value.region_id){
          this.hospitalsByRegion.push(value)
        }
      });
    }
  },
  computed: {
    medicalReps (){
      return this.$store.getters['admin/GetMedicalReps'] 
    },
    items () {
      console.log(this.$store.getters['admin/GetVisits']);
      return this.$store.getters['admin/GetVisits']
    },
  },
  mounted() {
    this.getHospitals()
    this.getVisitType()
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
    }
  },
}
</script>



<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';


#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

</style>
